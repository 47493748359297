import styles from './styles';

export default (tabValue: string, value: string) => {
  if (tabValue === value) {
    return styles.selectedTab;
  }

  return styles.tab;
};

export const setLastSearchHistory = (payload) => {
  const storedData = localStorage.getItem('last_search');
  let parsedData = {};

  if (storedData) {
    parsedData = JSON.parse(storedData);

    parsedData = { ...parsedData, ...payload };
  } else {
    parsedData = {
      ...payload
    };
  }

  localStorage.setItem('last_search', JSON.stringify(parsedData));
};

export const getLastSearchHistory = () => {
  const storedData = localStorage.getItem('last_search');
  let parsedData = '';

  if (storedData) {
    parsedData = JSON.parse(storedData);
    parsedData = parsedData?.search_type;
  }

  return parsedData;
};
