import Head from 'next/head';
import { Box, Typography } from '@mui/material';
import SearchPropertyBox from '@foxtons/fdl/components/SearchPropertyBox/index';

import styles, { setBackgroundImage } from './styles';

export type Props = {
  title: string;
  subtitle: string;
  backgroundPhoto?: string;
  backgroundSizes?: {
    large: { url: string };
    medium: { url: string };
    small: { url: string };
    smallportrait: { url: string };
  };
  apiAutoCompleteProperty: {
    baseUrl: string;
    endpoint: string;
  };
};

export default function HeroSearchSection(props: Props) {
  const { title, subtitle, backgroundSizes, apiAutoCompleteProperty } = props;

  return (
    <Box
      component="section"
      sx={[
        styles.section as object,
        backgroundSizes ? setBackgroundImage(backgroundSizes) : false
      ]}
    >
      <Head>
        {typeof backgroundSizes?.medium?.url === 'string' && (
          <link
            rel="preload"
            href={backgroundSizes?.medium?.url}
            as="image"
            media="(min-width: 600px) and (max-width: 1199px)"
            fetchPriority="high"
          />
        )}
        {typeof backgroundSizes?.large?.url === 'string' && (
          <link
            rel="preload"
            href={backgroundSizes?.large?.url}
            as="image"
            media="(min-width: 1200px)"
            fetchPriority="high"
          />
        )}
      </Head>
      <Box sx={styles.titles}>
        <Typography variant="h1" component="h1">
          {title}
        </Typography>
        <Typography variant="h4" component="h2">
          {subtitle}
        </Typography>
      </Box>

      <Box>
        <SearchPropertyBox
          apiPropSearch={apiAutoCompleteProperty}
          isHomePage
          isOpen
        />
      </Box>
    </Box>
  );
}
