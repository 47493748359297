import { type SxProps } from '@mui/material';
import { lightTheme } from '@foxtons/fdl/theme';

const { palette, spacing } = lightTheme;

import { type Props as HeroSearchSectionProps } from './index';

export const setBackgroundImage = (
  bgSize: HeroSearchSectionProps['backgroundSizes']
) => ({
  backgroundImage: !bgSize
    ? 'none'
    : {
        xs: `url(${bgSize.smallportrait.url})`,
        sm: `url(${bgSize.medium.url})`,
        lg: `url(${bgSize.large.url})`
      },
  boxShadow: 'inset 0 0 0 50vw rgba(0, 0, 0, 0.3)', // overlay
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
});

const styles: Record<string, SxProps> = {
  section: {
    marginTop: `var(--header-height)`,
    padding: {
      xs: `${spacing(8)} ${spacing(0.5)} ${spacing(3)}`,
      md: `${spacing(3)} ${spacing(2)} ${spacing(3)}`,
      xl: `${spacing(10)} ${spacing(2)} ${spacing(3)}`
    }
  },
  titles: {
    textAlign: 'center',
    color: palette.common.white,
    marginBottom: spacing(2),
    '& > h1, h4': {
      fontWeight: 400,
      textShadow: '0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.75)'
    },
    '& > *:not(:last-child)': {
      marginBottom: spacing(0.5)
    }
  }
};

export default styles;
