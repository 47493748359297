import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme';

const { palette, spacing } = lightTheme;

const searchBoxCommon = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  gap: '0.5rem',
  border: `0.063rem solid ${palette.lightishAqua.main}`,
  backgroundColor: palette.grey['50']
};

const styles: Record<string, SxProps> = {
  baseState: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '0.5rem'
  },

  // styles applied to select-dropdown component
  selectComponent: {
    backgroundColor: palette.lightishAqua.main,
    color: palette.primary.main,
    '& > div': {
      padding: '0.5rem 1rem',
      color: palette.primary.main,
      fontSize: {
        xs: '0.875rem',
        sm: '1rem'
      },
      fontWeight: 800
    },
    '& .MuiSelect-select': {
      padding: '0.5rem 1rem',
      color: palette.primary.main,
      '&:focus': {
        color: palette.primary.main
      }
    },
    '& svg': {
      color: palette.primary.main
    },
    '& .MuiMenuItem-root': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        border: `0.01rem solid ${palette.grey['100']}`,
        width: '75%',
        bottom: '0'
      }
    },
    '& fieldset': {
      border: 'none',
      '&:hover': {
        border: `0.063rem solid ${palette.primary.main}`
      }
    },
    '& option': {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '0.875rem',
      color: palette.grey.main,
      padding: '0.75rem'
    }
  },

  dropdownComponent: {
    minWidth: {
      xs: '0',
      md: '4rem'
    }
  },

  // styles applied to autoComplete dropdown
  autocompleteBox: {
    width: '100%',
    '& .MuiAutocomplete-popper': {
      'ul::-webkit-scrollbar': {
        width: '0.5rem'
      },

      'ul::-webkit-scrollbar-track': {
        background: palette.primary['200'],
        borderRadius: '2.5rem'
      },

      'ul::-webkit-scrollbar-thumb': {
        background: palette.primary.main,
        borderRadius: '2.5rem'
      }
    },
    '.MuiOutlinedInput-input': {
      fontSize: {
        xs: '0.75rem',
        sm: '1rem'
      },
      padding: '0'
    },
    '.MuiFormControl-root': {
      '& div': {
        padding: '0'
      }
    }
  },

  autocomplete: {
    minWidth: {
      xs: '0',
      md: '20rem'
    },
    '& fieldset': {
      border: 'none'
    },
    input: {
      '&::placeholder': {
        color: palette.grey['400'],
        opacity: 1
      }
    }
  },

  autocompleteDropdown: {
    display: 'flex',
    gap: '1.5rem',
    py: '0.75rem',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      border: `0.01rem solid ${palette.grey['100']}`,
      width: '30rem',
      bottom: '-0.375rem'
    }
  },

  mapIcon: {
    '& svg': {
      fill: palette.primary.main
    }
  },

  closeIcon: {
    color: palette.primary.main,
    fontSize: {
      xs: '0.75rem',
      md: '1rem'
    }
  },

  helperText: {
    mt: { xs: '0.35rem', md: '0.4rem' },
    pl: {
      xs: '5rem',
      md: '5.5rem'
    }
  },

  // styles for last search-btn
  searchBtn: {
    backgroundColor: palette.primary.main,
    border: `0.063rem solid ${palette.primary.main}`,
    color: palette.grey['50'],
    padding: '0.5rem 0.8rem',
    minWidth: 0,
    '& .MuiButton-endIcon': {
      marginLeft: {
        xs: 0,
        sm: spacing(0.5)
      }
    },
    '& svg.MuiSvgIcon-root ': {
      width: '1.4rem',
      fontSize: '1.4rem'
    },
    '&:hover': {
      opacity: '0.8',
      backgroundColor: palette.primary.main
    },
    '& .btnLabel': {
      display: {
        xs: 'none',
        sm: 'inline'
      }
    }
  },

  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '0.5rem',
    border: `0.063rem solid ${palette.lightishAqua.main}`,
    borderRadius: '0.5rem'
  },

  simpleSearchBox: {
    ...searchBoxCommon,
    borderRadius: '0.25rem'
  },

  simpleSearchBoxOffice: {
    ...searchBoxCommon,
    borderRadius: '0rem'
  },

  searchBtnAlternative: {
    '& svg': {
      width: '1rem',
      color: palette.primary['300']
    }
  }
};

export default styles;
