import React, { useEffect, useState } from 'react';
import {
  Box,
  Autocomplete,
  TextField as MuiTextField,
  Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../Button';
import MapMarker from '../../icons/MapMarker';
import styles from './styles';
import {
  freeTextFeildSpecialCharsRegex,
  invalidCharsRegexForSearch
} from '../../utils/index';
import { setLastSearchHistory } from './utility';

export interface LibSearchPropertyAutoCompleteProps {
  api: {
    baseUrl?: string;
    endpoint?: string;
  };
  propertyType?: string;
  variant?: 'widget' | 'simpleSearchBox' | 'simpleSearchBoxOffice';
  placeholderText?: string;
  setShowSpecialErrorMsg?: (value: boolean) => void;
  onInputFocus?: () => void;
}

function LibSearchProperty({
  api,
  propertyType,
  variant = 'widget',
  placeholderText = 'search',
  setShowSpecialErrorMsg,
  onInputFocus
}: LibSearchPropertyAutoCompleteProps) {
  const [fetchedResult, setFetchedResult] = useState([]);
  const [, setErrorMsg] = useState('');
  const [prefetchedData, setPrefetchedData] = useState([]);

  // API Integration here via fetch: INETGRATE ENDPOINTS here
  const getPropertyList = async (keyword: string) => {
    const resultRaw = await fetch(`${api.baseUrl}${api.endpoint}${keyword}`);
    const resultJson = await resultRaw.json();

    setFetchedResult(resultJson.length ? resultJson : []);
  };

  // State to manage entered value
  // Need to change interface after API-integration here
  const [value, setValue] = React.useState<{
    label: string;
    code: string;
  } | null>(null);

  const handleValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: {
      label: string;
      code: string;
    } | null
  ) => {
    setValue(newValue);
  };

  // State to manage while user is typing
  const [inputValue, setInputValue] = React.useState('');

  const handleInputValueChange = (
    event: React.MouseEvent<HTMLElement>,
    newInputValue: string | null
  ) => {
    const cleanValue = newInputValue.replace(invalidCharsRegexForSearch, '');

    if (!freeTextFeildSpecialCharsRegex.test(newInputValue)) {
      setErrorMsg('Special characters are not allowed');
      setShowSpecialErrorMsg?.(true);
    } else {
      setErrorMsg('');
      setShowSpecialErrorMsg?.(false);
    }

    setInputValue(cleanValue);
  };

  const getSearchResultsURL = (option = '') => {
    const searched =
      (option || inputValue)
        ?.replace(/&/g, '')
        .replace(/\s+/g, ' ')
        .replace(/[^a-z0-9 /]/gi, '')
        .trim()
        .replace(/ /g, '-')
        .toLowerCase() || '';

    const propertyURL = `/${
      propertyType === 'properties-for-sale-sold'
        ? 'properties-for-sale'
        : propertyType
    }/${searched || 'south-east-england'}${
      propertyType === 'properties-for-sale-sold' ? '?sold=only' : ''
    }`;

    return propertyURL;
  };

  const prefetch = (option = '') => {
    const pagePath = getSearchResultsURL(option);

    if (prefetchedData.includes(pagePath)) {
      return;
    }

    setPrefetchedData([...prefetchedData, pagePath]);
    fetch(`${process.env.NEXT_PUBLIC_URL}${pagePath}`);
  };

  const handleSetSession = () => {
    setLastSearchHistory({ search_type: propertyType });
    sessionStorage.setItem('isLocationSearch', 'true');
    sessionStorage.removeItem('isMapSearch');
    sessionStorage.removeItem('isRadiusTypeSearch');
    sessionStorage.removeItem('radius');
    sessionStorage.removeItem('travel_locations');
    sessionStorage.removeItem('travel_places');
    sessionStorage.removeItem('isNearMeSearch');
    sessionStorage.removeItem('isDrawSearch');
  };

  const handleSearchClick = (option = '') => {
    handleSetSession();

    window.location.href = getSearchResultsURL(option);
  };

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        handleSetSession();

        window.location.href = getSearchResultsURL(value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (inputValue.length) {
      getPropertyList(inputValue);
    } else {
      setFetchedResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    /* eslint react/jsx-no-useless-fragment: 0 */
    // Rob - Fix this so the fragment isn't needed
    <>
      {variant === 'widget' ? (
        <Box sx={styles.baseState}>
          <Box id="autocomplete" sx={styles.autocompleteBox}>
            <Autocomplete
              id="combo-box-demo"
              options={fetchedResult}
              value={value}
              onChange={handleValueChange}
              inputValue={inputValue}
              onInputChange={handleInputValueChange}
              freeSolo
              clearIcon={<CloseIcon sx={styles.closeIcon} />}
              sx={styles.autocomplete}
              filterOptions={(options) => options}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleSearchClick(option)}
                  onMouseEnter={() => prefetch(option)}
                >
                  <Box sx={styles.autocompleteDropdown}>
                    <MapMarker sx={styles.mapIcon} />
                    <Typography>{option}</Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    onFocus: onInputFocus
                  }}
                  placeholder="Find a property by area or postcode"
                />
              )}
            />
          </Box>
          <Box id="searchBtn">
            <Button
              sx={styles.searchBtn}
              style={{ cursor: 'pointer' }}
              onClick={() => handleSearchClick()}
              onMouseEnter={() => prefetch()}
              aria-label="search icon"
              endIcon={<SearchIcon />}
            >
              <Typography variant="body1" className="btnLabel">
                Search
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={styles[variant]}>
          <Box id="searchBtn">
            <Button
              sx={styles.searchBtnAlternative}
              style={{ cursor: 'pointer' }}
              onClick={() => handleSearchClick()}
              onMouseEnter={() => prefetch()}
              aria-label="search icon"
            >
              <SearchIcon />
            </Button>
          </Box>
          <Box id="autocomplete" sx={styles.autocompleteBox}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={fetchedResult}
              value={value}
              onChange={handleValueChange}
              inputValue={inputValue}
              onInputChange={handleInputValueChange}
              freeSolo
              clearIcon={<CloseIcon sx={styles.closeIcon} />}
              sx={styles.autocomplete}
              filterOptions={(options) => options}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box
                    sx={styles.autocompleteDropdown}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSearchClick(option)}
                    onMouseEnter={() => prefetch(option)}
                  >
                    <MapMarker sx={styles.mapIcon} />
                    {option}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <MuiTextField {...params} placeholder={placeholderText} />
              )}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export const SearchProperty = React.memo(LibSearchProperty);
